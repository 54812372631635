import { useState } from "react";
import { Surface } from "../../components/ui/Surface";
import { Tooltip } from "../../components/ui/Tooltip/Tooltip";
import {
  Stack,
  Box,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Button,
  MenuItem,
  Select,
  Typography,
  styled,
  TextField,
} from "@mui/material";
import Icon from "../../components/ui/Icon";
import SendIcon from "@mui/icons-material/Send";
import { Toolbar } from "../ui/Toolbar";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import Person2Icon from "@mui/icons-material/Person2";
import { useSelector } from "react-redux";
import ModelSelection from "./ModelSelection";
import ChatAbstract from "./ChatAbstract";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CommentIcon from "@mui/icons-material/Comment";

const Text = styled(Typography)({
  fontFamily: "Raleway",
});

export default function ChatHome() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
  const [query, setQuery] = useState("");
  const { user } = useSelector((state) => state.user);
  const [selectedModel, setSelectedModel] = useState("");

  const [chatHistory, setChatHistory] = useState([]);
  console.log("Chat History", chatHistory);
  const [selectedChat, setSelectedChat] = useState(null);
  console.log("selectedChat", selectedChat);

  const handleStartNewChat = () => {
    setIsCreatingNewChat(true);
    setSelectedChat(null);
    setQuery("");
  };

  const handleBackToHome = () => {
    setIsCreatingNewChat(false);
    setSelectedChat(null);
    setQuery("");
  };

  const handleSendQuery = () => {
    if (isCreatingNewChat && query.trim() !== "") {
      // Create a new chat with the first query as the title
      const newChat = {
        id: chatHistory.length + 1,
        title: query.trim(),
        messages: [
          { type: "user", text: query.trim() },
          // You can add a default bot response here if needed
        ],
      };
      setChatHistory((prev) => [...prev, newChat]);
      setSelectedChat(newChat);
      setIsCreatingNewChat(false);
      setQuery("");
    } else if (selectedChat && query.trim() !== "") {
      // Append message to existing chat
      const updatedChat = {
        ...selectedChat,
        messages: [
          ...selectedChat.messages,
          { type: "user", text: query.trim() },
          // You can handle bot responses here
        ],
      };
      const updatedChatHistory = chatHistory.map((chat) =>
        chat.id === selectedChat.id ? updatedChat : chat
      );
      setChatHistory(updatedChatHistory);
      setSelectedChat(updatedChat);
      setQuery("");
    }
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setIsCreatingNewChat(false);
    setQuery("");
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    // Reset states when closing the chat
    if (isChatOpen) {
      setIsCreatingNewChat(false);
      setSelectedChat(null);
      setQuery("");
    }
  };

  const handleInput = (e) => {
    setQuery(e.target.value);
  };

  const capitalizeFirstWord = (text) => {
    const words = text.split(" ");
    if (words.length > 0) {
      words[0] =
        words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    }
    return words.join(" ");
  };

  return (
    <Surface
      className={`flex items-center justify-center gap-1 fixed z-40 ${
        isChatOpen
          ? "bottom-0 right-1 w-[80%] sm:w-96 h-[90vh] lg:h-[92vh] xl:h-[92.5vh] 2xl:h-[95vh] overflow-y-auto p-1"
          : "bottom-8 right-12 "
      } `}
    >
      {isChatOpen ? (
        <Stack spacing={2} sx={{ px: 3, py: 2, height: "100%", width: "100%" }}>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction={"row"}
            // mt={5}
          >
            {(isCreatingNewChat || selectedChat) && (
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={handleBackToHome}
                sx={{
                  color: "#7A7979",
                  // fontWeight: "600",
                  background: "white",
                  boxShadow: "none",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#F6FAFD",
                    color: "#14224B",
                    boxShadow: "none",
                  },
                  px: 1,
                  textTransform: "capitalize",
                }}
              >
                Back
              </Button>
            )}

            <Text
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontWeight: 700,
                ml: !isCreatingNewChat && !selectedChat ? { xs: 4, sm: 8 } : 0,
                color: "#14224B",
              }}
            >
              Ask ResearchPal
            </Text>
            <Button onClick={toggleChat} sx={{ marginLeft: "auto" }}>
              <CloseIcon sx={{ fontSize: "19px", color: "#353535" }} />
            </Button>
          </Stack>

          {/* Interface 1: Display Start New Chat and Chat History */}
          {!selectedChat && !isCreatingNewChat && (
            <Box sx={{ width: "100%", height: "100%" }}>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleStartNewChat}
                  sx={{
                    borderRadius: "6px",
                    // px: 6.5,
                    width: "100%",
                    // py: 1,
                    fontFamily: "Raleway",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    boxShadow: "none",
                    background: "#1E4F6B",
                    "&:hover": {
                      background: "#163C54",
                    },
                  }}
                >
                  New Chat
                </Button>
              </Stack>

              {/* {chatHistory && chatHistory.length > 0 && (
                <Box sx={{ my: 4 }}>
                  <Text
                    sx={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "#14224B",
                    }}
                  >
                    Your Conversations
                  </Text>
                </Box>
              )} */}

              <Stack
                spacing={2}
                my={4}
                sx={{
                  height: chatHistory.length === 0 ? "92%" : "66vh",
                  overflow: "auto",
                }}
              >
                {chatHistory.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Text sx={{ color: "#7A7979", fontSize: "14px" }}>
                      No Chats History
                    </Text>
                  </Box>
                ) : (
                  chatHistory.map((chat, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        key={chat.id}
                        onClick={() => handleChatClick(chat)}
                        variant="outlined"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          alignItems: "center",
                          fontWeight: 600,
                          width: "100%",
                          borderRadius: "6px",
                          background: "#f6f6f6",
                          color: "#535D7B",
                          border: "1px solid #EFEFEF",
                          px: 2,
                          py: 1,
                          // fontSize: "16px",
                          "&:hover": {
                            border: "1px solid #EFEFEF",
                            background: "#EFEFEF",
                            color: "#14224B",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={1}
                        >
                          <CommentIcon sx={{ fontSize: "18px" }} />
                          <Text
                            sx={{ fontSize: "14px", textTransform: "none" }}
                          >
                            {chat.title.length > 17
                              ? ` ${chat.title.substring(0, 17)}...`
                              : ` ${chat.title}`}
                          </Text>
                        </Stack>

                        <Text
                          sx={{
                            fontSize: "11px",
                            fontFamily: "Robotto",
                            textTransform: "capitalize",
                          }}
                        >
                          {new Date().toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </Text>
                      </Button>
                    </Box>
                  ))
                )}
              </Stack>
            </Box>
          )}

          {(isCreatingNewChat || selectedChat) && (
            <ChatAbstract
              isCreatingNewChat={isCreatingNewChat}
              selectedChat={selectedChat}
              query={query}
              handleSend={handleSendQuery}
              handleInput={handleInput}
              user={user}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
            />
          )}
        </Stack>
      ) : (
        <Tooltip
          title="Ask ResearchPal"
          tippyOptions={{
            delay: 0,
          }}
        >
          <Toolbar.Button onClick={toggleChat}>
            {/* <Icon name="ChatIcon"  /> */}
            <ChatIcon sx={{ height: "100%" }} />
          </Toolbar.Button>
        </Tooltip>
      )}
    </Surface>
  );
}
