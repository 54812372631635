import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getRefs } from "../../../references/refsSlice"; // import your action
import TailSpinLoader from "../../../../components/TailSpinLoader";

const ReferenceEditor = ({}) => {
  const dispatch = useDispatch();
  const { refFormats, loading } = useSelector((state) => state.refs);
  console.log("RefFormats", refFormats);
  const [referenceList, setReferenceList] = useState([]);
  const [format, setFormat] = useState(
    localStorage.getItem("selectedFormat") || "apa"
  );
  const [query, setQuery] = useState("");

  // Dispatch the action to load refFormats
  useEffect(() => {
    dispatch(getRefs()); // Dispatch the action to fetch reference formats
  }, [dispatch]);

  useEffect(() => {
    if (!refFormats) {
      dispatch(getRefs(localStorage.getItem("projectId")));
    }
  }, []);

  const formats = () => {
    let arr = [];
    for (const [key, value] of Object.entries(refFormats)) {
      if (value[format]) {
        arr.push(value[format]);
      }
    }

    let filtered = arr.filter((i) =>
      i.toString().toLowerCase().includes(query)
    );

    return filtered;
  };
  console.log("Formats", formats());

  return (
    <Box
      sx={{
        padding: {
          xs: "2px 25px",
          sm: "2px 50px",
          md: "2px 100px",
          lg: "2px 250px",
        },
      }}
    >
      {loading ? (
        <Typography>
          <TailSpinLoader />{" "}
          {/* Display loading indicator while fetching data */}
        </Typography>
      ) : refFormats && formats().length > 0 ? (
        <Box mt={4} sx={{ borderTop: "1px solid #222222" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: 700, mb: 2, mt: 2 }}
            >
              References
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#7A7979" }}>
              To edit reference(s) go to the “References” section.
            </Typography>
          </Stack>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {formats().map((item, i) => (
              <li key={i} style={{ marginBottom: "10px" }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    overflowX: "auto",
                    color: "#353535",
                    fontFamily: "Roboto",
                  }}
                >
                  <span style={{ fontWeight: "700", fontSize: "16px" }}>
                    {i + 1}.{" "}
                  </span>
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      ) : null}
    </Box>
  );
};

export default ReferenceEditor;
