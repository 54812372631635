import * as Popover from "@radix-ui/react-popover";
import React, { useCallback, useEffect } from "react";
import { Surface } from "../../../../ui/Surface";
import { Spinner } from "../../../../ui/Spinner/Spinner";
import { Toolbar } from "../../../../ui/Toolbar";
import { Stack, Skeleton, Box, Typography, IconButton } from "@mui/material";
import { Button } from "../../../../ui/Button/Button";
import { Add, Error, OpenInNew } from "@mui/icons-material";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { clearRefs, getRefs } from "../../../../../../references/refsSlice";
import { saveResearchReferences } from "../../../../../../../apiservice";
import { DropdownButton } from "../../../../ui/Dropdown/Dropdown";
import Icon from "../../../../ui/Icon";
export const Btn = styled(Button)({
  width: "fit-content",
  height: "24px",
  padding: "6px 12px 6px 12px",
  borderRadius: "4px",
  background: "#F6F6F6",
  color: "black",
  ":hover": {
    background: "#E2E2E2",
  },
});
const CitationDialogue = ({
  citationDialogueRef,
  handleAccept,
  handleReject,
  askPalData,
  loading,
  citationData,
  showCitationDialog,
  setShowCitationDialog,
  setCitationData,
  editor,
  citeErrorMessage,
}) => {
  console.log("citationData:", citationData);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        citationDialogueRef.current &&
        !citationDialogueRef.current.contains(event.target)
      ) {
        setShowCitationDialog(false);
        citationData([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const AddCitation = (text, link) => {
    const {
      state: {
        doc,
        selection,
        selection: { empty, from, to },
      },
    } = editor;

    // editor
    //   .chain()

    //   .insertContent(
    //     `<a href="${link}" target="_blank" rel="noreferrer">${text}</a>`
    //   )

    //   .focus()
    //   .run();
    editor?.commands.insertContentAt(
      to,
      `<a href="${link}" target="_blank" rel="noreferrer">${text}</a>`,
      {
        updateSelection: false,
        parseOptions: {
          preserveWhitespace: "full",
        },
      }
    );
  };
  const AddRefrence = async (value) => {
    const projectId = localStorage.getItem("projectId");
    let req = {
      researchProjectId: projectId,
    };

    // Check if references object exists
    let references = req.references || {};

    // Add new reference to the object
    references[value] = value;

    // Update references object in the request
    req.references = references;
    console.log(req);
    // Update references
    await saveResearchReferences(req);
    dispatch(clearRefs());
    // saveData();
  };
  return (
    <Popover.Root open={showCitationDialog}>
      <Popover.Trigger asChild>
        <Toolbar.Button
          onClick={() => {
            setShowCitationDialog(!showCitationDialog);
          }}
          //   icon="research"
          //   tooltip="Ask Research Pal"
        />
      </Popover.Trigger>

      <Popover.Content
        asChild
        side="bottom"
        sideOffset={8}
        className="transform translate-y-0 translate-x-[213px]"
      >
        <Surface
          ref={citationDialogueRef}
          className=" justify-center items-center p-2  min-w-[43rem] max-w-[43rem] h-[18rem] overflow-auto"
        >
          {loading ? (
            <Stack
              sx={{ padding: "10px" }}
              // className="dark:bg-[#444444] rounded-sm h-full"
              className=" rounded-sm h-full"
            >
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton variant="rectangular" height={118} />
            </Stack>
          ) : citationData.length > 0 ? (
            <>
              {citationData.map((arr, i) => {
                const isAdded = false;
                return (
                  <Stack
                    key={i}
                    mt={"4px"}
                    direction="row"
                    sx={{
                      background: "#eeeeee",
                      p: "0px 5px 0px 5px",
                      borderRadius: "6px",
                      m: "4px",
                    }}
                  >
                    <Stack spacing={1} p={1}>
                      <Box
                        sx={{
                          display: "inline-block",
                          fontSize: "14px",
                          marginLeft: "6px",
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                          {arr?.title}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          {arr?.summary}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          {arr?.snippet}
                        </Typography>
                      </Box>
                      <Stack direction="row" spacing={2} alignItems={"center"}>
                        <DropdownButton
                          className={
                            "w-auto border border-gray-500 rounded-md py-[4px]"
                          }
                          // onClick={() => {
                          //   AddCitation(arr.inTextCitation, arr.link);
                          //   AddRefrence(arr.citation);
                          //   dispatch(
                          //     getRefs(localStorage.getItem("projectId"))
                          //   );
                          // }}
                          onClick={async () => {
                            AddCitation(arr.inTextCitation, arr.link);
                            await AddRefrence(arr.citation); // Wait for AddRefrence to complete
                            dispatch(
                              getRefs(localStorage.getItem("projectId"))
                            ); // Dispatch after citation is added
                          }}
                        >
                          <Icon name="Plus" />
                          Add Citation
                        </DropdownButton>
                        <a href={arr?.link} target="_blank" rel="noreferrer">
                          <DropdownButton
                            className={
                              "w-auto border border-gray-500 rounded-md py-[4px]"
                            }
                          >
                            <Icon name="SquareArrowOutUpRight" />
                            View in new tab
                          </DropdownButton>
                        </a>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : citationData.length < 1 ? (
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              // className="dark:bg-[#171717] bg-gray-100 rounded-md h-full"
              className=" bg-gray-100 rounded-md h-full"
            >
              {/* <Error fontSize="large" className="dark:text-white text-black" /> */}
              <Error fontSize="large" className=" text-black" />
              <Typography
                sx={{ fontSize: "12px" }}
                // className="dark:text-white text-black"
                className=" text-black"
              >
                {/* Something went wrong. Please try again!
                 */}
                {citeErrorMessage
                  ? citeErrorMessage
                  : "Something went wrong. Please try again!"}
              </Typography>
            </Stack>
          ) : null}
        </Surface>
      </Popover.Content>
    </Popover.Root>
  );
};

export default CitationDialogue;
