import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  styled,
  useMediaQuery,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Check } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LanguageIcon from "@mui/icons-material/Language";
import TuneIcon from "@mui/icons-material/Tune";
import Drawer from "@mui/material/Drawer";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ReactGA from "../../Analytics";
import {
  saveAbstracts,
  saveResearchSearchReference,
  saveReferenceNew,
  sendErrorMail,
} from "../../apiservice";
import magnifyingLoader from "../../assets/images/magnifyingLoader.gif";
import SearchHistory from "../../assets/images/search-history.svg";
import BtnClear from "../../components/BtnClear";
import SearchField from "../../components/SearchField";
import Toast from "../../components/Toast";
import { baseDomain } from "../../repo/Repository";
import { clearUser } from "../Admin/userSlice";
import AbstractDialogue from "../library/AbstractDialogue";
import { clearAllLitReview } from "../literature review/reviewSlice";
import { clearAllInsights } from "../paper insights/insightsSlice";
import { clearProject } from "../projects/projectSlice";
import { clearRefs, getRefs } from "../references/refsSlice";
import FeedbackDialogue from "../sidebar/FeedbackDialogue";
import { PapersDomain } from "./PapersDomain";
import { Publications } from "./PublicationTypes";
import SemanticItemBox from "./SemanticItemBox";
import FilterDrawer from "./FilterDrawer";
import {
  addItems,
  addSemanticPapers,
  addSemanticStatus,
  clearSemanticPapers,
  handleSemanticPaperQuery,
  setSemanticLoading,
  setSemFilters,
  setOffsets,
  clearSemFilters,
  clearOffsets,
  toggleDropdown,
} from "./semanticPaperSlice";
import { setLastSearchPapersRoute } from "./routeSlice";
import { BtnSave, ItemBox, RecomendationBtn, RootStyle, Title } from "./styles";
import SemanticItems from "./SemanticItems";
import SearchGuidelineDialog from "./SearchGuidelineDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  addPapers,
  handlePaperQuery,
  setDomain,
  setLoading,
  setTabValue,
} from "./paperSlice";
export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "12px",
  marginTop: 5,
  marginRight: 3,
  color: "#353535CC",
});
export const TextRale = styled(Typography)({
  fontFamily: "Raleway",
});

const useSaveCurrentRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // Dispatch action to save the current route to Redux state when navigating away
      dispatch(setLastSearchPapersRoute(location.pathname));
    };
  }, [location, dispatch]);
};

function SemanticPapers() {
  useSaveCurrentRoute();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery("(max-width: 768px)");
  const {
    semanticPaperQuery,
    semanticPapers,
    semanticLoading,
    semanticSaveStatus,
    filters,
    offsets,
  } = useSelector((state) => state.semanticPaper);
  const isFilterApplied = useSelector(
    (state) => state.semanticPaper.isFilterApplied
  );
  const { user } = useSelector((state) => state.user);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedDoi, setSelectedDoi] = useState({});
  const [selectedAbstract, setSelectedAbstract] = useState(null);
  const [openSecondDialogue, setOpenSecondDialogue] = React.useState(false);
  const [openRecommendation, setOpenRecommendation] = React.useState(false);
  const [semanticError, setSemanticError] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasSemanticPapers, setHasSemanticPapers] = useState(true);

  const [researchError, setResearchError] = useState(false);
  const [hasPapers, setHasPapers] = useState(true);

  // const [isOpen, setIsOpen] = useState({
  //   papers: false,
  //   domains: false,
  //   publications: false,
  // });
  const isOpen = useSelector((state) => state.semanticPaper.isOpen);
  const [openAccessPdf, setOpenAccessPdf] = useState(false); // Default value
  const [openDialog, setOpenDialog] = useState(false);

  const handleAccessChange = (event) => {
    // setOpenAccessPdf(event.target.checked);
    dispatch(
      setSemFilters({ ...filters, openAccessPdfs: event.target.checked })
    );
  };

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const handleItemClick = (item) => {
    console.log("Navigating with item:", item); // Add this line to debug
    setSelectedAbstract(item);
    localStorage.setItem("selectedItem", JSON.stringify(item));
    dispatch(addItems(item));
    localStorage.setItem("Items", item);
    navigate(
      `/projects/${localStorage.getItem("projectTitle")}/search-papers/${
        item.paperId
      }`
      // { state: { item } }
    );
  };

  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Ensure that text is not null or undefined
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // const toggleDropdown = (dropdownName) => {
  //   setIsOpen({
  //     ...isOpen,
  //     [dropdownName]: !isOpen[dropdownName],
  //   });
  // };

  const handleToggleDropdown = (dropdownName) => {
    dispatch(toggleDropdown(dropdownName));
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  const currentYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(null); // Default start year to previous year
  const [endYear, setEndYear] = useState(null);
  const yearRange = startYear ? `${startYear}-${endYear}` : false;

  // const handleStartYearChange = (event) => {
  //   setStartYear(event.target.value);
  // };

  // const handleEndYearChange = (event) => {
  //   setEndYear(event.target.value);
  // };

  const handleStartYearChange = (event) => {
    const startYear = event.target.value;
    // dispatch(setSemFilters({ startYear: startYear }));
    dispatch(
      setSemFilters({
        ...filters,
        startYear: startYear === "" ? null : parseInt(startYear),
      })
    );
    // const endYear = filters.yearRange ? filters.yearRange.split("-")[1] : null;
    // handleYearChange(startYear, endYear);
  };

  const handleEndYearChange = (event) => {
    const endYear = event.target.value;
    // dispatch(setSemFilters({ endYear: endYear }));
    dispatch(
      setSemFilters({
        ...filters,
        endYear: endYear === "" ? null : parseInt(endYear),
      })
    );

    // const startYear = filters.yearRange
    //   ? filters.yearRange.split("-")[0]
    //   : null;
    // handleYearChange(startYear, endYear);
  };

  const handleApplyFilters = () => {
    // Dispatch an action to trigger loading state
    // dispatch(setLoading(true));

    // // Run the script with updated filters
    // RunScript(1);
    dispatch(clearSemanticPapers());
    handleSearch();
    // setOffset(0);
    dispatch(clearOffsets());
    setDrawerOpen(false);
  };

  const location = useLocation();
  const [displayedRecords, setDisplayedRecords] = useState(9);
  const [offset, setOffset] = useState(0);
  console.log("semanticSaveStatus:", semanticSaveStatus);
  const [state, setState] = useState({
    domain: user?.domain,
    otherDomain: "",
  });
  const [selectedDomains, setSelectedDomains] = useState("");
  const [selectedPublications, setSelectedPublications] = useState("");
  // const handleDomainSelection = (domain) => {
  //   // Toggle the selected domain
  //   if (selectedDomains.includes(domain)) {
  //     setSelectedDomains(selectedDomains.replace(`${domain},`, ""));
  //   } else {
  //     setSelectedDomains(selectedDomains + `${domain},`);
  //   }
  // };

  const handleDomainSelection = (domain) => {
    console.log("domain:", domain);
    const { fieldsOfStudy } = filters;
    let fieldsArray = fieldsOfStudy ? fieldsOfStudy.split(",") : [];

    if (fieldsArray.includes(domain)) {
      fieldsArray = fieldsArray.filter((item) => item !== domain);
    } else {
      fieldsArray.push(domain);
    }

    const newFieldsOfStudy = fieldsArray.join(",");
    dispatch(
      setSemFilters({
        fieldsOfStudy: newFieldsOfStudy,
      })
    );
  };

  // const handlePublicationSelection = (publication) => {
  //   if (selectedPublications.includes(publication)) {
  //     setSelectedPublications(
  //       selectedPublications.replace(`${publication},`, "")
  //     );
  //   } else {
  //     setSelectedPublications(selectedPublications + `${publication},`);
  //   }
  // };

  const handlePublicationSelection = (publication) => {
    console.log("publication:", publication);
    const { publicationTypes } = filters;
    console.log("publicationTypes:", publicationTypes);
    let pubArray = publicationTypes ? publicationTypes.split(",") : [];

    if (pubArray.includes(publication)) {
      pubArray = pubArray.filter((item) => item !== publication);
    } else {
      pubArray.push(publication);
    }

    const newPub = pubArray.join(",");
    console.log("newPub:", newPub);
    dispatch(
      setSemFilters({
        publicationTypes: newPub,
      })
    );
  };

  // const handleYearChange = (startYear, endYear) => {
  //   const yearRange = filters.startYear
  //     ? `${filters.startYear}-${filters.endYear}`
  //     : false;
  //   // const yearRange = startYear ? `${startYear}-${endYear}` : false;
  //   dispatch(setSemFilters({ ...filters, yearRange: yearRange }));
  // };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Papers",
    });
  }, []);

  const RunScript = (currentOffset = 0, clearPapers = true) => {
    if (clearPapers) {
      dispatch(clearSemanticPapers());
      setSemanticError(false);
      // setHasSemanticPapers(true)
    }
    try {
      dispatch(setSemanticLoading(true));
      let domain = state.domain;
      // let fieldsOfStudy = selectedDomains.slice(0, -1) || false;
      // let fieldsOfStudy = filters.fieldsOfStudy || false;
      // let publicationTypes = selectedPublica tions
      //   ? selectedPublications.slice(0, -1)
      //   : false;
      // let publicationTypes = filters.publicationTypes || false;
      // let yearRange = filters.yearRange || false;
      let {
        startYear,
        endYear,
        fieldsOfStudy,
        openAccessPdfs,
        publicationTypes,
      } = filters;
      let yearRange = startYear && endYear ? `${startYear}-${endYear}` : false;
      if (startYear && !endYear) {
        yearRange = `${startYear}-${currentYear}`;
      } else if (!startYear && endYear) {
        yearRange = `2000-${endYear}`;
      }
      let info = {
        semSearch: true,
        query: semanticPaperQuery,
        yearRange: yearRange,
        openAccessPdf: openAccessPdfs || false,
        publicationTypes: publicationTypes || false,
        fieldsOfStudy: fieldsOfStudy || false,
        offset: currentOffset,
        //sort: false ,
      };

      let requestOptions = {
        method: "POST",
        body: JSON.stringify(info),
        redirect: "follow",
      };
      fetch(`${baseDomain}/script`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let res = JSON.parse(result);
          console.log("res:", res);
          // if (
          //   res.data.error ===
          //   "Invalid Session Id! You might be logged in from two different devices"
          // ) {
          //   Toast("error", res.data.error);
          //   dispatch(setLoading(false));
          //   // handleLogout();
          // }
          // console.log("result:", result);
          if (
            res.data.response === "" ||
            res.data.response === "Something went wrong!"
          ) {
            dispatch(setSemanticLoading(false));
            setSemanticError(true); // Set error state here
            setHasSemanticPapers(false);
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Semantic Search",
              error: res.data.response || "Empty Response",
              query: semanticPaperQuery,
            });

            dispatch(setTabValue(1)); // Switch to ResearchPal tab
            Toast(
              "info",
              "Semantic Search is having an issue. You've been switched to ResearchPal for uninterrupted work.",
              {
                icon: <InfoOutlinedIcon style={{ color: "#535D7B" }} />, // Set icon color to #EFEFEF
                progressStyle: {
                  backgroundColor: "#535D7B", // Set progress bar color to #EFEFEF
                },
              }
            );

            handleResearchPalSearch(semanticPaperQuery); // Call ResearchPal search function

            return;
          }

          dispatch(addSemanticPapers(res.data.responseArr));
          dispatch(setSemanticLoading(false));
          setSemanticError(false);
          setHasSemanticPapers(true);

          // dispatch(clearPaperQuery());
        })
        .catch((error) => {
          setTimeout(() => {
            dispatch(setSemanticLoading(false));
          }, 10000);

          // dispatch(setLoading(false));
        });
    } catch (err) {
      dispatch(setSemanticLoading(false));
    }
  };

  const handleResearchPalSearch = (query) => {
    dispatch(handlePaperQuery(query));
    dispatch(setDomain("Other"));
    dispatch(setLoading(true));
    const info = {
      query: query,
      search: true,
      email: localStorage.getItem("email"),
      page: 1,
      domain: "Other",
      token: localStorage.getItem("token") || "",
    };

    let requestOptions = {
      method: "POST",
      body: JSON.stringify(info),
      redirect: "follow",
    };

    fetch(`${baseDomain}/script`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        if (
          res.data.response === "" ||
          res.data.response === "Something went wrong!"
        ) {
          dispatch(setLoading(false));
          setResearchError(true);
          setHasPapers(false);
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `ResearchPal Search, Other`,
            error: res.data.response || "Empty Response",
            query: query,
          });
          return;
        }

        dispatch(addPapers(res.data.responseArr));
        dispatch(setLoading(false));
        setResearchError(false);
        setHasPapers(true);
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 10000);
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  const handleSearch = () => {
    if (!semanticPaperQuery) {
      Toast("error", "Enter your query");
      return;
    }
    dispatch(setSemanticLoading(true));
    setSemanticError(false);
    // setOffset(0);
    dispatch(clearOffsets());
    // RunScript();
    RunScript(0, true);
  };

  // const handleClear = () => {
  //   // dispatch(clearPaperQuery());
  //   setOffset(0);
  //   dispatch(clearSemanticPapers());
  //   setSemanticError(false);
  //   dispatch(setSemanticLoading(false));
  //   setHasSemanticPapers(true);
  // };
  useEffect(() => {
    const currProject = localStorage.getItem("projectTitle");
    const projectTitle = localStorage.getItem("projectTitle");
    if (currProject !== projectTitle) {
      dispatch(clearSemFilters());
    }
  }, []);

  const handleClear = () => {
    dispatch(clearSemanticPapers());
    dispatch(clearOffsets());
    setSemanticError(false);
    dispatch(setSemanticLoading(false));
    setHasSemanticPapers(true);
  };

  const handleFilterClear = () => {
    // dispatch(clearSemFilters());

    // Toast("success", "Filters are cleared successfully");
    if (isFilterApplied) {
      dispatch(clearSemFilters());
      Toast("success", "Filters are cleared successfully");
    }
  };

  const savePaper = async (item) => {
    // if (saveLoading && selectedDoi[item.doi]) {
    //   return;
    // }
    // setSaveLoading(true);
    // setSelectedDoi((prev) => ({ ...prev, [item.doi]: item.doi }));

    // const key = item.doi || item.title;
    const key = item.doi && item.doi !== "None" ? item.doi : item.title;

    if (saveLoading[key]) {
      return;
    }
    setSaveLoading((prev) => ({ ...prev, [key]: true }));
    setSelectedDoi((prev) => ({ ...prev, [key]: true }));

    let req = {
      saveReference: true,
      researchProjectId: localStorage.getItem("projectId"),
      title: item.title,
      doi: item.doi,
    };
    const referenceRes = await saveReferenceNew(req);
    console.log("referenceRes:", referenceRes);
    if (referenceRes?.data?.data?.sourceObj === "") {
      Toast("err", "NO data found");
      return;
    }
    if (referenceRes?.data?.data?.sourceObj) {
      // let refExt = referenceRes?.data?.sourceObj?.reference;
      // if (!refExt) {
      //   console.error("Reference not found in the response");
      //   return;
      // }
      console.log("hellll");
      let absInfo = {
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
        paper: {
          title: item.title,
          author: item.author,
          year: item.year,
          url: item.url,
          abstracts: item.abstracts,
          journal: item.journal,
          doi: item.doi,
          uploadDate: Date.now() / 1000,
        },
        reference: referenceRes?.data?.data?.sourceObj?.reference,
      };
      let res = await saveAbstracts(absInfo);
      dispatch(getRefs(localStorage.getItem("projectId")));

      //for saving references
      // let req = {
      //   researchProjectId: localStorage.getItem("projectId"),
      //   doi: item.doi,
      // };

      // let references = req.references || {};
      // references[item.citation] = item?.citation;
      // req.references = references;
      // await saveResearchSearchReference(req);

      dispatch(clearRefs());
      // setSaveLoading(false);
      // let copy = JSON.parse(JSON.stringify(selectedDoi));
      // // delete copy[item.doi];
      // delete copy[key];
      // setSelectedDoi(copy);

      setSaveLoading((prev) => ({ ...prev, [key]: false }));
      setSelectedDoi((prev) => {
        let copy = { ...prev };
        delete copy[key];
        return copy;
      });

      if (res?.abstractSaved) {
        Toast("success", "Reference added & abstract saved in your library.");
        // setSaveStatus((prev) => ({ ...prev, [item.doi]: true }));
        // dispatch(addSemanticStatus({ ...semanticSaveStatus, [item.doi]: true }));
        dispatch(addSemanticStatus({ ...semanticSaveStatus, [key]: true }));
      }
    }
  };

  const handleQuery = (e) => {
    const { value } = e.target;
    dispatch(handleSemanticPaperQuery(value));
  };
  const handleCloseSecondDialogue = () => {
    setOpenSecondDialogue(false);
  };
  const handleRecommendation = () => {
    setOpenRecommendation(true);
  };
  const handleCloseRecommendation = () => {
    setOpenRecommendation(false);
  };

  // const handleLoadMore = () => {
  //   setOffset((prevOffset) => {
  //     const newOffset = prevOffset + 10;
  //     RunScript(newOffset, false);
  //     return newOffset;
  //   });
  // };

  const handleLoadMore = () => {
    dispatch(setOffsets(offsets + 10));
    RunScript(offsets + 10, false);
  };
  const handleDomainInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <RootStyle
      sx={{
        padding: { xs: "16px 8px 0px 8px", md: "16px 32px 0px 32px" },
        minHeight: "auto",
      }}
    >
      <SearchField
        place="Write your research topic here ..."
        val={semanticPaperQuery}
        onChange={handleQuery}
        load={semanticLoading}
        onClick={handleSearch}
      />

      {/* <Stack direction={mediaQuery ? "column" : "row"}>
        <Text
          sx={{
            fontWeight: 600,
            width: mediaQuery ? "100%" : "10%",
            display: "flex",
            alignItems: "center",
            paddingLeft: "12px",
          }}
        >
          Select Domain :
        </Text>
        <Select
          fullWidth
          size="small"
          sx={{
            width: mediaQuery ? "100%" : "90%",
            background: "#F6F6F6",
            borderRadius: "6px",
            border: "none",
            height: "32px",
            "& fieldset": {
              padding: "0",
              margin: "0",
              border: "none",
            },
            marginTop: "8px",
          }}
          name="domain"
          value={state.domain}
          onChange={handleDomainInput}
        >
          {Domains.map((domain) => (
            <MenuItem value={domain}>
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Raleway",
                  }}
                >
                  {domain}
                </Typography>
                {(domain == "Computer Science" ||
                  domain == "Medical & Health Sciences" ||
                  domain == "Humanities and Social Sciences" ||
                  domain == "Business Studies") && (
                  <Tooltip
                    title={
                      <Typography fontSize={"12px"} fontFamily={"12px"}>
                        Currently our automated Literature Review and Search is
                        optimized for this domain.
                      </Typography>
                    }
                    placement="top"
                  >
                    <InfoOutlined
                      sx={{ color: "#353535", mr: 1, fontSize: "18px" }}
                    />
                  </Tooltip>
                )}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </Stack> */}
      {/* {state.domain == "Other" && (
        <Stack justifyContent={"end"} direction={mediaQuery ? "column" : "row"}>
          <FormControl size="small" sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              type="text"
              placeholder="Please Enter your domain"
              sx={{
                width: mediaQuery ? "100%" : "100%",
                fontSize: "12px !important",
              }}
              InputProps={{
                style: {
                  padding: "0px 0px 0px 0px !important",
                  margin: "0px 0px 0px 0px !important",
                  fontFamily: "Raleway",
                  fontSize: "12px",
                },
              }}
              name="otherDomain"
              value={state.otherDomain}
              onChange={handleDomainInput}
            />
          </FormControl>
        </Stack>
      )} */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Title
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#4E4E4E",
            mr: "8px",
          }}
        >
          We would love to hear your recommendations regarding the journals we
          should search for your domain
        </Title>

        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#353535 !important",
            textTransform: "none",
          }}
          onClick={handleRecommendation}
        >
          Add Recommendations
        </Button>
      </Box> */}

      {!semanticLoading && semanticPapers.length == 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              my: 1,
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<TuneIcon />}
              sx={{
                // background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                // "&:hover": {
                //   background:
                //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                // },
                background: "#1E4F6B",
                boxShadow: "none",
                "&:hover": {
                  background: "#163C54",
                },
                py: "3px",
                textTransform: "capitalize",
                width: "90px",
                fontFamily: "Raleway",
              }}
              onClick={toggleDrawer}
            >
              Filters
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleToggleDialog}
              sx={{
                background: "#1E4F6B",
                boxShadow: "none",
                "&:hover": {
                  background: "#163C54",
                },
                textTransform: "capitalize",
                fontFamily: "Raleway",
              }}
            >
              See Guidelines
            </Button>
          </Box>
          <SearchGuidelineDialog
            open={openDialog}
            onClose={handleToggleDialog}
          />

          <FilterDrawer
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            handleCloseDrawer={handleCloseDrawer}
            handleToggleDropdown={handleToggleDropdown}
            handleStartYearChange={handleStartYearChange}
            handleEndYearChange={handleEndYearChange}
            handleAccessChange={handleAccessChange}
            handleDomainSelection={handleDomainSelection}
            handlePublicationSelection={handlePublicationSelection}
            handleFilterClear={handleFilterClear}
            handleApplyFilters={handleApplyFilters}
            filters={filters}
            isOpen={isOpen}
            PapersDomain={PapersDomain}
            Publications={Publications}
            currentYear={currentYear}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: mediaQuery ? "50vh" : "70vh",
              boxShadow: "none",
            }}
          >
            <img src={SearchHistory} alt="" />
            <Title
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#4E4E4E",
                my: 1,
              }}
            >
              {semanticError
                ? "There was trouble fetching search results from Semantic API. Please try again!"
                : "No Search Results"}
            </Title>
            {semanticError && (
              <Title
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#4E4E4E",
                  my: 1,
                }}
              >
                Or switch to ResearchPal Search
              </Title>
            )}
          </Box>
        </>
      )}

      {semanticPapers.length > 0 && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Title
            sx={{ fontWeight: 400, fontFamily: "Roboto", pb: { xs: 1, sm: 0 } }}
          >
            Your search results:
          </Title>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap="8px"
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<TuneIcon />}
              sx={{
                // background:
                //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%) !important",
                // "&:hover": {
                //   background:
                //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%) !imporatnt",
                // },
                background: "#1E4F6B",
                boxShadow: "none",
                "&:hover": {
                  background: "#163C54",
                },
                py: "3px",
                textTransform: "capitalize",
                fontFamily: "Raleway",
              }}
              onClick={toggleDrawer}
            >
              Filters
            </Button>

            <FilterDrawer
              drawerOpen={drawerOpen}
              toggleDrawer={toggleDrawer}
              handleCloseDrawer={handleCloseDrawer}
              handleToggleDropdown={handleToggleDropdown}
              handleStartYearChange={handleStartYearChange}
              handleEndYearChange={handleEndYearChange}
              handleAccessChange={handleAccessChange}
              handleDomainSelection={handleDomainSelection}
              handlePublicationSelection={handlePublicationSelection}
              handleFilterClear={handleFilterClear}
              handleApplyFilters={handleApplyFilters}
              filters={filters}
              isOpen={isOpen}
              PapersDomain={PapersDomain}
              Publications={Publications}
              currentYear={currentYear}
            />

            <Button
              variant="contained"
              size="small"
              onClick={handleToggleDialog}
              sx={{
                background: "#1E4F6B",
                boxShadow: "none",
                "&:hover": {
                  background: "#163C54",
                },
                textTransform: "capitalize",
                fontFamily: "Raleway",
              }}
            >
              See Guidelines
            </Button>
            <SearchGuidelineDialog
              open={openDialog}
              onClose={handleToggleDialog}
            />

            <BtnClear onClick={handleClear} />
          </Stack>
        </Stack>
      )}
      <Box
        sx={{
          height:
            semanticPapers.length > 0 || semanticLoading
              ? "75vh"
              : (semanticPapers.length > 0 || semanticLoading) && !mediaQuery
              ? "60vh"
              : "",
          overflowY: "auto",
        }}
      >
        {semanticPapers.length > 0 &&
          semanticPapers.map((item) => (
            <SemanticItemBox
              key={item.id}
              item={item}
              handleItemClick={handleItemClick}
              truncateText={truncateText}
              savePaper={savePaper}
              saveLoading={saveLoading}
              selectedDoi={selectedDoi}
              semanticSaveStatus={semanticSaveStatus}
            />
          ))}

        {semanticPapers.length > 0 && !semanticLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 2,
            }}
          >
            {/* <RecomendationBtn
              sx={{ background: "#353535 !important", color: "#fff" }}
              onClick={handleLoadMore}
            >
              Load More ...
            </RecomendationBtn> */}
            {hasSemanticPapers ? (
              <RecomendationBtn
                sx={{
                  // background:
                  //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  // "&:hover": {
                  //   background:
                  //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                  // },
                  background: "#1E4F6B",
                  "&:hover": {
                    background: "#1E4F6B",
                  },
                  color: "#fff",
                }}
                onClick={handleLoadMore}
              >
                Load More...{" "}
              </RecomendationBtn>
            ) : (
              <Box
                sx={{
                  color: "#4E4E4E",
                  padding: "10px",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                No more Papers
              </Box>
            )}
          </Box>
        )}

        {semanticPapers.length > 0 && semanticLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={magnifyingLoader} alt="" style={{ width: "50px" }} />
          </Box>
        )}

        {semanticPapers.length === 0 && semanticLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                color: "#353535",
              }}
            >
              Fetching Papers...
            </Typography>
            <img src={magnifyingLoader} alt="" />
          </Box>
        )}
      </Box>

      <FeedbackDialogue
        openDialogue={openRecommendation}
        onClose={handleCloseRecommendation}
      />
    </RootStyle>
  );
}

export default SemanticPapers;
