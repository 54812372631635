import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  // fontSize: "13px",
  color: "#353535",
  padding: "10px",
});

const GuidelineDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          //   flexWrap: "wrap-reverse",
        }}
      >
        <Text sx={{ fontWeight: 600 }}>
          Guidelines for Using the "Literature Review" Feature
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: "absolute",
            right: 8,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Text>
          <strong>Must Read:</strong> To ensure optimal results from the
          "Literature Review", please enter clear and concise research
          questions, topics, or paper titles. This tool is not designed for
          conversational AI interactions, so avoid using long-form prompts or
          questions. It is specifically built to generate comprehensive
          literature reviews from focused academic queries, as conversational
          input e.g GPT-like prompts may lead to errors.
        </Text>
        <Text>
          To get the best results from the "Literature Review" feature, please
          follow these guidelines:
        </Text>
        <Text>
          <strong>1. Keep Your Query Precise and Focused:</strong> Enter a
          single, clear research topic or question. Avoid using multiple topics
          or complex, lengthy prompts for better results.
        </Text>
        <Text>
          <strong>2. Use Specific Keywords:</strong> Include essential keywords
          related to your topic or question to guide the search effectively.
        </Text>
        <Text>
          <strong>3. Avoid General or Vague Queries:</strong> General prompts
          may lead to less relevant results. Be as specific as possible about
          the aspect of the topic or question you are interested in.
        </Text>
        <Text>
          <strong>4. Single Question or Topic per Query:</strong> Each prompt
          should address only one question or topic. If you have multiple
          queries, enter them separately to get clear and focused literature
          reviews for each.
        </Text>
        <Text>
          <strong>5. Check Your Query for Clarity:</strong> Before submitting,
          ensure your topic or question is well-defined and free from
          unnecessary details. Clear and direct questions help the ResearchPal
          retrieve the most relevant academic sources.
        </Text>
        <Text>
          Following these guidelines will help you obtain a more accurate and
          useful literature review.
        </Text>
      </DialogContent>
    </Dialog>
  );
};

export default GuidelineDialog;
