import React from "react";
import ModelSelection from "./ModelSelection";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  styled,
  Typography,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import SendIcon from "@mui/icons-material/Send";

const Text = styled(Typography)({
  fontFamily: "Raleway",
});

const ChatAbstract = ({
  isCreatingNewChat,
  selectedChat,
  query,
  handleSend,
  handleInput,
  user,
  selectedModel,
  setSelectedModel,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line
      handleSend(); // Call the handleSend function
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModelSelection
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        user={user}
      />

      {/* Chat History */}

      {!isCreatingNewChat && selectedChat && (
        <Box mt={4}>
          {/* Chat Title */}
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            mb={2}
          >
            <Person2Icon
              fontSize="15px"
              sx={{ color: "#353535CC", mt: 0.5, mr: 1 }}
            />
            <Text sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {selectedChat.title.length > 30
                ? selectedChat.title.substring(0, 30)
                : selectedChat.title}
            </Text>
          </Stack> */}

          {/* Chat Messages */}
          <Stack
            spacing={1.5}
            sx={{
              flexGrow: 1,
              maxHeight: "52vh",
              overflowY: "auto",
              mb: 2,
            }}
          >
            {selectedChat.messages.map((msg, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent={msg.type === "user" ? "flex-end" : "flex-start"}
              >
                {msg.type !== "user" && (
                  <Person2Icon
                    fontSize="15px"
                    sx={{ color: "#353535CC", mt: 0.5, mr: 1 }}
                  />
                )}
                <Box
                  sx={{
                    background: msg.type === "user" ? "#DCF8C6" : "#F8F8F8",
                    padding: "8px",
                    borderRadius: "6px",
                    maxWidth: "70%",
                  }}
                >
                  <Text sx={{ fontSize: "14px", px: 1 }}>{msg.text}</Text>
                </Box>
                {msg.type === "user" && (
                  <Person2Icon
                    fontSize="15px"
                    sx={{ color: "#353535CC", mt: 0.5, ml: 1 }}
                  />
                )}
              </Stack>
            ))}
          </Stack>
        </Box>
      )}

      {/* Input Box */}
      <FormControl fullWidth sx={{ mt: "auto" }}>
        <TextField
          sx={{
            background: "#F8F8F8",
            "& .MuiInputBase-input": {
              fontFamily: "Raleway",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#1AB394", // Change border color when focused
              },
            },
          }}
          value={query}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          multiline
          rows={2}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={handleSend}
                  disabled={!query.trim()}
                  sx={{ minWidth: 0, padding: "4px" }}
                >
                  <SendIcon sx={{ color: "#1AB394" }} />
                </Button>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: {
              fontFamily: "Raleway",
              fontSize: "12px",
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

export default ChatAbstract;
